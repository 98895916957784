import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';

import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import { Hold } from '../@types/hold.d';
import { Event } from '../@types/event.d';
import { RootState } from '../store';
import { Loading, SkeletonCard } from '../components';
import { createCancelRequest, getEventByKey, } from '../services/apiService';
import { Registration } from '../@types/registration.d';
import { setCancelOrTransfer, setCurrentEventType, setEventSelected } from '../store/User/UserSlice';

import "./checkout/CheckoutForm.css";
import { BlueBanner } from './BlueBanner';

import pageicon from '../assets/icons/page.png'
import { useHistory } from 'react-router-dom';

interface PendingRegProps {
    eventKey: string;
}

const ConfirmedHold: React.FC<PendingRegProps> = ({ eventKey }) => {
    const { user } = useAuth0();
    const [event, setEvent] = useState<Event | null>(null);

    useEffect(() => {
        async function getEventDetails(eventKey: string) {
            if (user) {
                let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
                let e = await getEventByKey(eventKey, clientId)
                setEvent(e);
            }
        };

        getEventDetails(eventKey);
        // eslint-disable-next-line
    }, [user]);

    return (
        <Fragment>
            {
                event
                &&
                <Row
                    data-testid='registration_row'
                    className={'border-top'}
                >
                    <Col xs='12'>
                        <Row style={{padding: "10px 30px", backgroundColor: "#FAF9FD"}} className={"noto border-bottom"} >
                            {event.eventTitle}
                        </Row>
                        <Row style={{display: "flex", alignItems: "center", padding: "10px 40px"}}>
                            <Col xs='4' className="noto">
                                {moment(event.startDate).format('MMM DD, YYYY')} - {moment(event.endDate).format('MMM DD, YYYY')}
                            </Col>
                            <Col xs='4' className="noto">
                                {event.description}
                            </Col>
                            <Col xs='4' className="noto" style={{textAlign: 'center'}}>
                                <strong>Processing payment</strong>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}

const CurrentRegistrations: React.FC = () => {
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const history = useHistory();
    const { currentHolds, currentEventType, registrations, registrationsLoading, eventTypes } = useSelector((state: RootState) => state.user);

    const [registationToCancel, setRegistationToCancel] = useState<Registration | null>(null);
    const [confirmedHolds, setConfirmedHolds] = useState<Array<Hold>>([]);
    const [allEvents, setAllEvents] = useState<Array<Event>>([]);

    const getGreeting = () => {
        return user
            ? `Hello ${user?.name}`
            : 'Hello';
    }

    useEffect(() => {
        if (currentHolds && currentHolds.length > 0) {
            let cholds = currentHolds?.filter(x => x.status === "Confirmed");
            setConfirmedHolds(cholds);
        }
    }, [currentHolds])

    useEffect(() => {
        let evtArray: Array<Event> = [];
        // eslint-disable-next-line
        eventTypes.map((item, index) => {
            if (item.events && item.events.length > 0) {
                evtArray.push(...item.events);
            }
        })
        setAllEvents(evtArray);
    }, [eventTypes])

    return (
        <Fragment>
            {
                registrationsLoading
                    ?
                    <SkeletonCard />
                    :
                    
                    confirmedHolds?.length === 0 && registrations?.length === 0
                        ?
                        <BlueBanner>
                            <div>
                                {getGreeting()}, you are not registered for any events.
                            </div>
                        </BlueBanner>
                        :
                        <Card className='card-custom gutter-b'>
                            <Card.Header style={{alignItems: 'center', justifyContent: 'flex-start'}}>
                                <div className='card-title font-weight-bolder mr-5'>
                                    Your Current Registration
                                </div>
                                <img src={pageicon} style={{height: "1.25%", width: "1.25%"}}/>
                            </Card.Header>
                            <Card.Body style={{padding: "0px 13.5px"}} className='text-left'>
                                <Fragment> 
                                {
                                    registrations?.length === 0
                                        ?
                                        confirmedHolds.map((item, index) => {
                                            return (
                                                <ConfirmedHold key={index} eventKey={item.eventKey}/>
                                            )
                                        })
                                        :
                                        registrations.map((item, index) => { 
                                            let evt = allEvents?.find(x => x.eventKey === item.eventKey);
                                            let evtType = eventTypes.find(x => x.programCode === evt?.programCode);
                                            return (
                                                <Row
                                                    data-testid='registration_row'
                                                    key={index}
                                                    className={'border-top'}
                                                >
                                                    <Col xs='12'>
                                                        <Row style={{padding: "10px 30px", backgroundColor: "#FAF9FD"}} className={"noto border-bottom"} >
                                                            {evtType ? evtType.eventType : item.title}
                                                        </Row>
                                                        <Row style={{display: "flex", alignItems: "center", padding: "10px 40px"}}>
                                                            <Col xs='4' className="noto">
                                                                {moment(item.startDate).format('MMM DD, YYYY')} - {moment(item.endDate).format('MMM DD, YYYY')}
                                                            </Col>
                                                            <Col xs='4' className="noto">
                                                                {item.description}
                                                            </Col>
                                                            <Col xs='4' className="noto">
                                                                {
                                                                    registationToCancel?.eventKey === item.eventKey
                                                                        ?
                                                                        <Loading height={50} width={50} />
                                                                        :
                                                                        <Fragment>
                                                                            <button
                                                                                style={{display: "flex", marginLeft: "auto"}}
                                                                                data-testid='start_cancel_button'
                                                                                className='btn btn-outline-primary btn-sm mr-3'
                                                                                onClick={() => {
                                                                                        setRegistationToCancel(item);
                                                                                        dispatch(setEventSelected(JSON.stringify(item)));
                                                                                        dispatch(setCancelOrTransfer(true));
                                                                                        history.push('/request')
                                                                                    }
                                                                                }
                                                                            >Request Transfer or Cancellation</button>
                                                                        </Fragment>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Fragment>
                            </Card.Body>
                        </Card>
            }
        </Fragment >
    );
};

export default CurrentRegistrations;
