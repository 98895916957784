import React, { useEffect, useRef } from "react";
import IdleTimer from 'react-idle-timer'
import { useInterval } from "../utils/useInterval";
import { Button, Modal, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const oneMinute = 60000;

const Timeout = () => {
	const idleTime = useRef(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const [timeLeft, setTimeLeft] = React.useState<number>(59);
	const [warning, setWarning] = React.useState<string>();
	const idleTimeOut = useRef<any>(null);
    const { logout } = useAuth0();

    // OnIdle
	const onIdle = () => {
		setIsOpen(true);
		idleTimeOut.current = setTimeout(() => {
			logOut();
		}, oneMinute);
	};

	//Stay Active
	const stayActive = () => {
		setIsOpen(false);
		setTimeLeft(59);
		clearTimeout(idleTimeOut.current);
	};

	//LogOut
	const logOut = () => {
		logout({
            returnTo: process.env.REACT_APP_ROOT_URL
        });
	};

	useInterval(
		() => {
			setTimeLeft(timeLeft - 1);
		},
		isOpen ? 1000 : null
	);

	useEffect(() => {
		let phrase = "Your session will expire in {{timeLeft}}. Do you need more time?";
		setWarning(phrase.replace("{{timeLeft}}", `0:${timeLeft}`));
	});

	useEffect(() => {
		if (isOpen) {
			document.title = "" + warning;
		} else {
			document.title = "Registration Portal | Center for Creative Leadership";
		}
	}, [isOpen, timeLeft]);

	let timeoutVal = (process.env.REACT_APP_ENVIRONMENT === "PROD" ? 50 : 1) * oneMinute;

    return (
		<div>
            <Modal
                show={isOpen}
                backdrop='static'
                keyboard={false}
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter' className="noto">
                        Session Timeout
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="noto pl-5 pr-5">
                        {warning}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        data-testid='clear_hold_button'
                        className='btn btn-sm btn-danger'
                        onClick={() => logOut()}>
                        No
                    </Button>
                    <Button
                        data-testid='clear_hold_button'
                        className='btn btn-sm btn-outline-primary'
                        onClick={() => stayActive()}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
			<IdleTimer
				ref={idleTime as any}
				timeout={timeoutVal}
				onIdle={onIdle}
			></IdleTimer>
		</div>
	);
};

export default Timeout;
